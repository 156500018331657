import {Helmet} from "react-helmet";
import Navibar from "./microComponents/navbar";
import {Link} from "react-router-dom";
import React, {useEffect, useRef, useState} from "react";
import {Container} from "react-bootstrap";
import {Image, Transformation} from "cloudinary-react";
import UniversityListItem from "./microComponents/universityListItem";
import axios from "axios";
import {apiProtocol, apiurl} from "./microComponents/urls";
import {useTranslation} from "react-i18next";

/**
 * Used for (list of) Universities page
 *
 */
export default function University() {
    const [imageSize, setImageSize] = useState('60vh')
    const contentRef = useRef(null);
    const [position, setPosition] = useState(0);
    const [uniData, setUniData] = useState([])
    const [error, setError] = useState('');
    const [numDivsToShow, setNumDivsToShow] = useState(0)
    const { t } = useTranslation();

    useEffect(() => {
        const interval = setInterval(() => {
            setNumDivsToShow(numDivsToShow + 1);
        }, 4000);

        return () => clearInterval(interval);
    }, [numDivsToShow]);

    // Get university data
    useEffect(() => {
        axios.get(apiProtocol+apiurl+'/universities').then((res) => {
            setUniData(res.data)
        }).catch(() => {
            setError('Error fetching resource')
        })
    }, [])

    /**
     * Set green bloc to image size
     *
     * @param event
     */
    const handleImageLoad = (event) => {
        const height = event.target.height;
        setImageSize(height);
    }

    // Resize green block upon screen resize
    useEffect(() => {
        const handleResize = () => {
            const height = document.getElementById('uni-image').height;
            setImageSize(height);
            setNumDivsToShow(calculateNumDivsToShow());
        }
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        }
    }, []);

    /**
     * Calculates the number of divs to show based on the height of the viewport and each div
     *
     * @returns {number}
     */
    const calculateNumDivsToShow = () => {
        const bounding = contentRef.current.getBoundingClientRect();
        const contentHeight = bounding.height;
        const viewportHeight = window.innerHeight;
        const divHeight = 10 * 16; // 10rem in pixels
        const headerHeight = 90; // header height in pixels

        const topOffset = bounding.top < 0 ? Math.abs(bounding.top) : 0;
        const numDivsAboveViewport = Math.ceil(topOffset / divHeight);

        const numDivsBelowViewport = Math.ceil((viewportHeight - headerHeight - Math.abs(position)) / divHeight);
        const numDivs = numDivsAboveViewport + numDivsBelowViewport + 2;
        return Math.max(Math.min(numDivs, Math.ceil(contentHeight / divHeight)), 4);
    };

    let touchStartY = 0;

    /**
     * Scroll loading logic
     *
     * @param event
     */
    const handleScroll = (event) => {
        event.preventDefault();

        let delta = 0;

        if (event.type === 'wheel') {
            delta = event.deltaY;
        } else if (event.type === 'touchmove') {
            delta = event.touches[0].clientY - touchStartY;
            touchStartY = event.touches[0].clientY;
        }

        // Calculate the maximum scroll based on the height of the content and the viewport
        const contentHeight = contentRef.current.getBoundingClientRect().height;
        const viewportHeight = window.innerHeight;
        const maxScroll = contentHeight - (viewportHeight * 0.5);

        // Update the position state variable with the delta, but limit it to the maximum scroll
        setPosition((prevPosition) => {
            const newPosition = prevPosition + (event.type === 'wheel' ? - delta : + delta);
            return Math.max(-maxScroll, Math.min(0, newPosition));
        });

        // Update the number of divs to show based on the new position
        setNumDivsToShow(calculateNumDivsToShow());
    };

    /**
     * Scroll listeners
     *
     * @param event
     */
    const handleTouchStart = (event) => {
        touchStartY = event.touches[0].clientY;
    };

    useEffect(() => {
        const element = contentRef.current;
        element.style.transform = `translateY(${position}px)`;
    }, [position]);

    useEffect(() => {
        if ('ontouchstart' in window) {
            window.addEventListener('touchstart', handleTouchStart, { passive: false });
            window.addEventListener('touchmove', handleScroll, { passive: false });
            window.addEventListener('touchend', () => touchStartY = 0);
        } else {
            window.addEventListener('wheel', handleScroll, { passive: false });
        }

        setNumDivsToShow(calculateNumDivsToShow());

        return () => {
            if ('ontouchstart' in window) {
                window.removeEventListener('touchstart', handleTouchStart);
                window.removeEventListener('touchmove', handleScroll);
                window.removeEventListener('touchend', () => touchStartY = 0);
            } else {
                window.removeEventListener('wheel', handleScroll);
            }
        }
    }, []);

    return (
        <div id="fullWrapper">
            <Helmet>
                <title>
                    Study Portugal - Institutions
                </title>
                <meta
                    name="description"
                    content={"View programs available at Portuguese universities, polytechnics, and other institutions across the country."}
                />
            </Helmet>
            <Navibar homeArea={<Link to="/" className="text-dark text-decoration-none">Home</Link>} />
            <div style={{height: "100vh"}} className={"position-relative overflow-hidden shadow"}>
                <Container className={"mx-2 100vh d-flex align-items-center"} style={{height: "100vh"}}>
                    <div
                        className="bg-success shadow-sm position-fixed w-50 mb-4"
                        id={"greenBg"}
                        style={{
                            marginTop: '10px',
                            float: 'left',
                            marginLeft: '-10px',
                            marginRight: '10px',
                            height: imageSize,
                        }}
                    />
                    <div
                        className={"bg-white shadow"}
                        style={{
                            height: 'fit-content',
                            marginTop: '-10px',
                            marginLeft: '10px',
                            marginBottom: '10vh',
                            marginRight: '-10px',
                            background: '#e6f7f5',
                            zIndex: "23",
                            width: '40vw'
                        }}
                    >
                        <Image
                            cloudName="studyportugal-pt"
                            className="w-100"
                            publicId="Coimbra22"
                            alt={t("institutions.imageAltText")}
                            id={'uni-image'}
                            onLoad={handleImageLoad}
                        >
                            <Transformation fetchFormat="auto" />
                        </Image>
                    </div>
                    <div
                        className={"bg-white p-4 end-0 shadow expandOnMobile"}
                        style={{
                            width: "60vw",
                            zIndex: "22",
                            position: 'fixed',
                            top: '8%',
                            transition: 'transform 0.3s ease-in-out',
                        }}
                        ref={contentRef}
                    >
                        <div className={"ms-sm-5"}>
                            <div className={"mb-4"}>
                                <div className={"h3"}> {t("navbar.Universities")} </div>
                                <div className={"text-secondary"}>
                                    {t("institutions.subtext")}
                                </div>
                            </div>
                            {uniData ? uniData.filter((data) => data.orgType === 'University').slice(0, numDivsToShow).map((res) =>
                                <UniversityListItem key={res.xCoordinates+res.yCoordinates} description={res.descr} city={res.city} pic={res.pictureURL} name={res.name} x={res.xCoordinates} y={res.yCoordinates} />
                            ) : ''}
                            {error}
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    )
}